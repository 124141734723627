#home {
    height: calc(100vh - 49px);
    // background-image: url('../../../public/bg.jpg');
    // background-position: top;
    // background-repeat: no-repeat;
    // background-size: cover;
    min-height: 100%;
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../../../public/bg.jpeg);
     background: linear-gradient(0deg, #23263a7F, #23263a7F), url(../../../public/bg.jpeg);
    
    background-size: cover;
    background-position: center;

    .intro-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: white;
        text-shadow: 2px 2px #496486;
    }

    .view-more-btn {
        cursor: pointer;
        // position: absolute;
        // bottom: 0px;
        // left: 50%;
        // transform: translate(-50%);
    }
}