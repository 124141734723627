#about {
    background-color: #2B4D9D;
}

#portfolio {
    background-color: #95ABD0;
}

#contact {
    background-color: #19318F;
}