#about {
    width: 100%;

    .container {
        min-height: 400px;
        display: flex;
        max-width: 1000px;
        text-align: center;
        // width: 100%;
        padding: 15px;
        margin-right: auto;
        margin-left: auto;
        justify-content: center;
    }

    .flex {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;

        .about-img-holder {
            min-width: 250px;
            max-width: 250px;
            // margin-right: 100px;

            img {
                // width: 100%;
                object-fit: cover;
                border-radius: 50%;
                height: 250px;
                width: 250px;
            }
        }

        .about-caption {
            color: #B0C4DE;
            max-width: 500px;

            .section-subtitle {}

            .section-title {
                margin-bottom: 20px;
            }

            .description {
                line-height: 24px;
            }
        }
    }
}