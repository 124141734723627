#portfolio {
    width: 100%;
    text-align: center;

    .section-subtitle {
        color: white;
        margin: 0;
        padding-top: 15px;
    }

    .section-title {
        color: white;
        margin: 0px;
        padding: 15px;
    }

    .container {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 20px;
        flex-wrap: wrap;

        .item {
            border-radius: 15px;
            display: flex;
            text-align: left;
            flex-direction: column;
            gap: 10px;
            width: 400px;
            min-height: 200px;
            max-height: 400px;
            height: 100%;
            padding: 20px;
            border: 1px solid white;
            color: white;

            &:hover {
                background-color: rgba(33, 35, 54, 0.6);
            }

            .title {
                font-size: 18px;
            }

            .description {
                font-size: 16px;
                height: 150px;
                line-height: 24px;
            }

            .visitBtn {
                width: 40px;
                color: white;
                border: 1px solid white;
                padding: 8px;
                border-radius: 10px;
                text-decoration: none;
                text-align: center;

                // &:hover {
                //     background-color: #2B4D9D;
                // }
            }

            img {
                width: 100%;
            }
        }
    }
}
