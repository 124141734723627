#contact {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    .section-title {
        color: #B0C4DE;
        margin: 0;
        padding: 15px;
    }

    .container {
        min-height: 100px;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .item {
        cursor: pointer;
        text-decoration: none;
            img {
                width: 35px;
                height: 35px;
            }
        }
    }
}