.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #212336;
    width: calc(100% - 30px);
    flex-wrap: wrap;
    z-index: 9999;

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    li,
    a,
    span {
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: white;
        text-decoration: none;
    }

    ul {
        list-style: none;

        li {
            display: inline-block;
            padding: 0px 20px;

            a {
                cursor: pointer;
                transition: all 0.3s ease 0s;

                &:hover {
                    color: #ff4a57;
                }
            }
        }
    }

    &.sticky {
        position: fixed;
        top: 0;
        background-color: white;

        li,
        a,
        span {
            color: #496486;
        }
    }

    .menu-icon {
        display: none;
    }

    @media screen and (max-width : 1024px) {
        &.is-mobile {
            ul {
                background-color: #212336;
                position: absolute;
                top: 64px;
                left: 0px;
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0px 15px;

                li {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    padding: unset;
                }
            }

            &.sticky {
              ul {
                background-color: white;
              }
            }
        }

        ul {
            display: none;
        }

        .menu-icon {
            display: block;

            img {
                width: 30px;
                height: 30px;
            }
        }
    }
}